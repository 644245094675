"use client";

import Logo from "@/components/Logo";
import React, { useState } from "react";
import Link from "next/link";
import {Navigation24Filled} from "@fluentui/react-icons"

function Navigation({ isUserLoggedIn }: { isUserLoggedIn: boolean }) {
  const [checked, setChecked] = useState<boolean>();
  const handleClick = () => {
    checked ? setChecked(!checked) : setChecked(checked);
  };
  return (
    <div className="drawer max-w-fit">
      <input
        id="my-drawer-3"
        type="checkbox"
        className="drawer-toggle "
        aria-label="menu-toggle"
        checked={checked}
      />
      <div className="drawer-content flex flex-col max-w-fit sm:max-w-full sm:w-full">
        {/* Navbar */}
        <div className="flex-grow navbar ">
          <div className="flex-none lg:hidden">
            <label
              htmlFor="my-drawer-3"
              className="items-center"
            >
              <Navigation24Filled />
            </label>
          </div>

          <div className="flex-none hidden lg:block">
            <ul className="menu menu-horizontal items-center text-base-content font-medium">
              {/* Site branding */}
              <li className="shrink-0 mr-4">
                {/* Logo */}
                <Link href="/">
                  <Logo className="group relative cursor-pointer" />
                </Link>
              </li>
              {/* Navbar menu content here */}
              <li>
                <Link href="/pricing" onClick={handleClick}>
                  Become A 10x Dev
                </Link>
              </li>
              <li>
                <Link href="/about">Meet Sam</Link>
              </li>

              {/* <li>
                <Link href="/contact" onClick={handleClick}>
                  Contact
                </Link>
              </li> */}
              {isUserLoggedIn ? (
                <li>
                  <Link href="/account" onClick={handleClick}>
                    Account
                  </Link>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
        {/* Page content here */}
      </div>
      <div className="drawer-side z-20">
        <label
          htmlFor="my-drawer-3"
          className="drawer-overlay"
          aria-label="drawer-navigation"
        ></label>

        <div className="menu w-80 h-full bg-base-100 text-base-content">
          {/* Sidebar content here */}
          <div className="shrink-0 m-3">
            {/* Logo */}
            <Link href="/">
              <Logo className="group relative cursor-pointer" />
            </Link>
          </div>
          <ul>
            <li>
              <Link href="/pricing">Get Your FastPocket</Link>
            </li>
            <li>
              <Link href="/about">Meet Sam</Link>
            </li>
          </ul>
          {/* <li>
            <Link href="/contact">Contact</Link>
          </li> */}
          {isUserLoggedIn ? (
            <li>
              <Link href="/account" onClick={handleClick}>
                Account
              </Link>
            </li>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navigation;
