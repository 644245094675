import React from "react";

import Image, { ImageProps } from "next/image";

interface LogoProps {
  label?: string;
  className?: string;
  imageProps?: ImageProps;
}

function Logo({ imageProps, ...props }: LogoProps) {
  return (
    <div
      className={
        props.className ?? " flex flex-row items-center justify-center"
      }
      {...props}
    >
      {/* <Image className="absolute hover:rotate-[360deg] transition duration-1000 ease-in-out" src={logoArrow} alt="Logo"/> */}

      <Image
        src={"/icons/combination-logo.svg"}
        alt="Follow us on Twitter"
        width={200}
        height={40}
        {...imageProps}
      />
    </div>
  );
}

export default Logo;
