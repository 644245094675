import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailValidationSchema } from "@/utils/form";
import { toast } from "react-toastify";
import pb from "@/lib/pocketbase";
import {Dismiss20Filled} from "@fluentui/react-icons"
import { redirect } from "next/navigation";

function ModalEmailChange({authString}:{authString:string}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(emailValidationSchema),
  });

  const onSubmit = async (data: any) => {
    console.log(data);
    pb.authStore.loadFromCookie(authString);
    !pb.authStore.isValid && redirect("/");
    try {
      if (await pb.collection("user").requestEmailChange(data.email)) {
        reset();
        document.getElementById("email-change-modal")?.click();
        document.getElementById("sign-in-modal")?.click();
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  return (
    <>
      <input
        type="checkbox"
        id="email-change-modal"
        className="modal-toggle"
        onClick={() => {
          reset();
        }}
      />
      <label htmlFor="email-change-modal" className="modal cursor-pointer">
        <label className="modal-box relative bg-base-100  max-w-full md:max-w-[450px] py-4 px-3 md:p-6">
          <div className="flex justify-end pb-2 select-none">
            <label
              htmlFor="email-change-modal"
              className="cursor-pointer text-base-content"
            >
              <Dismiss20Filled />
            </label>
          </div>
          <div>
            <div className="w-[100%] bg-gradient-to-r from-primary to-secondary px-6 pt-2 mt-3 pb-6 rounded-lg text-primary-content">
              <h3 className="pb-1 text-3xl font-bold md:text-3xl pt-6">
                Change Your Email
              </h3>
              <p className="text-sm md:text-base">Enter in your new email</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
              <div className="relative mt-6">
                <input
                  type="text"
                  className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary disabled:opacity-50 disabled:pointer-events-none "
                  placeholder="Your email…"
                  aria-label="Your email…"
                  autoComplete="on"
                  {...register("email")}
                />
                <div className="text-start text-sm italic text-error-content">
                  {errors.email?.message}&nbsp;
                </div>
              </div>

              <div className="flex flex-row w-full justify-between">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className={isSubmitting ? "btn btn-gray": "btn btn-primary"}
                >
                  Change Email
                  {isSubmitting && <div className="loading"></div>}
                </button>
              </div>
            </form>
          </div>
        </label>
      </label>
    </>
  );
}

export default ModalEmailChange;
