"use client";
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import GoogleAnalytics from "@/components/GoogleAnalytics";
import { ThemeProvider as NTThemeProvider } from 'next-themes'


if (typeof window !== 'undefined' && !!process.env.NEXT_PUBLIC_POSTHOG_KEY && !!process.env.NEXT_PUBLIC_POSTHOG_HOST) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST
  })
}

export function PHProvider({ children }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}

export function GTagProvider() {
  return process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ? (
    <GoogleAnalytics ga_id={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS} />
  ) : <></>
}

export function ThemeProvider({ children }) {
  return <NTThemeProvider defaultTheme = 'light'>{children}</NTThemeProvider>
}