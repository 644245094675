"use client";

import React, { useEffect, useState } from "react";
import Navigation from "@/components/Navigation";
import ModalSignUp from "@/components/Modals/ModalSignUp";
import ModalSignIn from "@/components/Modals/ModalSignIn";
import { getAuthCookie, getUser, logout } from "@/app/actions";
import { User } from "@/types";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import pb from "@/lib/pocketbase";
import { useTheme } from "next-themes";
import Link from "next/link";
import ModalPasswordReset from "./Modals/ModalPasswordReset";
import {Person24Filled, SignOut24Filled} from '@fluentui/react-icons'
import ModalEmailChange from "./Modals/ModalEmailChange";
import { usePathname, useRouter } from "next/navigation";

interface HeaderProps {
  isUserLoggedIn: boolean;
  authString: string;
}

export default function Header({ isUserLoggedIn, authString }: HeaderProps) {
  //Advice: Remove this and replace it with proper state management like Redux, Recoil or Zustand
  const [user, setUser] = useState<User | undefined>();
  const [statefulUser, setStatefulUser] = useState<User | undefined>();
  const pathName = usePathname();
  const router = useRouter();
  
  useEffect(() => {
    (async () => {
      const user = await getUser();
      setUser(user as User);
    })();
  }, [statefulUser]);


  const { theme, setTheme } = useTheme();
  return (
    <header className="absolute w-full z-30">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 md:pt-6">
        <div className="flex items-center justify-between h-20">
          <Navigation isUserLoggedIn={isUserLoggedIn} />

          {/* Desktop navigation */}
          <nav className=" md:flex">
            {/* Desktop sign in links */}
            <div className="flex flex-row grow sm:grow-0 items-center pr-2 gap-x-2">
              {/* <button
                className="btn btn-primary"
                onClick={() => setTheme("dark")}
              >
                dark
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setTheme("light")}
              >
                light
              </button> */}
              <label className="swap swap-rotate" aria-label="theme-toggle">
                {/* this hidden checkbox controls the state */}
                <input
                  type="checkbox"
                  className="opacity-0"
                  aria-label="theme-toggle-input"
                  onClick={() =>
                    theme === "light" ? setTheme("dark") : setTheme("light")
                  }
                />

                {/* sun icon */}
                <svg
                  className="swap-on fill-base-content w-6 h-6 z-10"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
                </svg>

                {/* moon icon */}
                <svg
                  className="swap-off fill-base-content w-6 h-6 z-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
                </svg>
              </label>

              {!isUserLoggedIn ? (
                <>
                  <button
                    onClick={() =>
                      (async (router?: AppRouterInstance) => {
                        console.log("firing");
                        const authCookie = await getAuthCookie();
                        pb.authStore.loadFromCookie(authCookie || "");
                        if (!authCookie || !pb.authStore.isValid) {
                          document.getElementById("sign-in-modal")?.click();
                        } else {
                          router?.push("/account");
                        }
                      })()
                    }
                    className={`btn btn-sm btn-ghost text-primary mr-1`}
                  >
                    Sign In
                  </button>
                  <button
                    onClick={() => {
                      router.push("/pricing");
                    }}
                    className={`btn btn-primary btn-sm text-primary-content `}
                    id="sign-up-modal-button"
                  >
                    Get First Access
                  </button>
                </>
              ) : (
                <div className="dropdown dropdown-end">
                  <div
                    tabIndex={0}
                    role="button"
                    className="btn btn-ghost btn-circle avatar"
                  >
                    <div className="w-6 rounded-full !flex justify-center items-center bg-base-300">
                      <Person24Filled />
                    </div>
                  </div>
                  <ul
                    tabIndex={0}
                    className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <li>
                      <Link href="/account" aria-label="profile">
                        <div className=" capitalize flex flex-row gap-x-2">
                          <Person24Filled />
                          {user?.displayName}
                        </div>
                      </Link>
                    </li>
                    <li onClick={() => logout()}>
                      <div className="capitalize flex flex-row gap-x-2">
                        <SignOut24Filled />
                        Logout
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </nav>
        </div>
      </div>
      <ModalSignIn />
      <ModalPasswordReset />
      <ModalEmailChange authString={authString} />
      <ModalSignUp setUser={setStatefulUser} />
    </header>
  );
}
