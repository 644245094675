"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInValidationSchema } from "@/utils/form";
import { login } from "@/app/actions";
import { toast } from "react-toastify";
import { useRouter } from "next/navigation";
import {Dismiss20Filled} from '@fluentui/react-icons'

function ModalSignIn() {
  const router = useRouter();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(signInValidationSchema),
  });

  const onSubmit = async (data: any) => {
    try {
      //login user
      const auth = (await login({ email: data.email, password: data.password }))
      if (
        auth.success
      ) {
        reset();
        document.getElementById("sign-in-modal")?.click();
        router.push("/account");
      } else {
        throw new Error(auth.response.message)
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  return (
    <>
      <input
        type="checkbox"
        id="sign-in-modal"
        className="modal-toggle"
        onClick={() => {
          reset();
        }}
      />
      <label htmlFor="sign-in-modal" className="modal cursor-pointer">
        <label className="modal-box relative bg-base-100  max-w-full md:max-w-[550px] py-4 px-3 md:p-6">
          <div className="flex justify-end pb-2 select-none">
            <label
              htmlFor="sign-in-modal"
              className="cursor-pointer text-base-content"
            >
              <Dismiss20Filled />
            </label>
          </div>
          <div>
            <div className="w-[100%] bg-gradient-to-r from-primary to-secondary px-6 pt-2 mt-3 pb-6 rounded-lg text-primary-content">
              <h3 className="pb-1 text-3xl font-bold md:text-3xl pt-6">
                Welcome back!
              </h3>
              <p className="text-sm md:text-base">Lets kick some more ass?</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
              <div className="relative mt-6">
                <input
                  type="text"
                  className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary disabled:opacity-50 disabled:pointer-events-none "
                  placeholder="Your email…"
                  aria-label="Your email…"
                  autoComplete="on"
                  {...register("email")}
                />
                <div className="text-start text-sm italic text-error-content">
                  {errors.email?.message}&nbsp;
                </div>
              </div>
              <div className="relative mt-1 mb-2 ">
                <input
                  type="password"
                  className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary disabled:opacity-50 disabled:pointer-events-none "
                  placeholder="Your password..."
                  aria-label="Your password..."
                  {...register("password")}
                />
                <div className="text-start text-sm italic text-error-content">
                  {errors.password?.message}&nbsp;
                </div>
              </div>

              <div className="flex flex-row w-full justify-between">
                <div className="flex flex-row gap-x-4">
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Sign In
                    {isSubmitting && <div className="loading"></div>}
                  </button>
                  <div className=" text-xs w-28 block">
                    <span className="whitespace-normal">
                      Dont have an account?{" "}
                    </span>

                    <span
                      onClick={() => {
                        document.getElementById("sign-in-modal")?.click();
                        document.getElementById("sign-up-modal")?.click();
                      }}
                      className="text-primary hover:text-primary/60 cursor-pointer "
                    >
                      Sign up
                    </span>
                  </div>
                </div>
                <button
                  onClick={() =>
                    document.getElementById("password-reset-modal")?.click()
                  }
                  className="btn btn-ghost text-primary capitalize border-none"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </label>
      </label>
    </>
  );
}

export default ModalSignIn;
